import { AfterViewInit, ChangeDetectorRef, Component, effect, EventEmitter, Inject, Input, input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoginType } from '@index/enums/login';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { BehaviorSubject } from 'rxjs';

import { GthUserModel } from '../../../../../sentinels/src/lib/models/user';
import { GTH_ENVIRONMENT, GthEnvironment } from '../../tokens/environment-tokens';
import { GthPasswordUtil } from '../../utils/password';
import { GthPolicyDialogComponent, POLICY_TABS } from './policies/policy-dialog.component';

const LOGIN_STORE = 'LOGIN_STORE';

interface SignUpAttempt {
  email: string;
  lastAttempt: Date;
}

export interface GthSignUpContract {
  fullName: string;
  email: string;
  password: string;
  genders: string[];
  sports: string[];
  photoUrl: string;
  pronouns: string;
}

export interface GthSignUp {
  contract?: GthSignUpContract;
  signInType: LoginType;
}

@Component({
  selector: 'gth-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class GthSignUpFormComponent implements AfterViewInit {
  /**
   * Route leveraged by Sign In button
   */
  @Input()
  signInRoute?: string;

  email = input<string>();
  name = input<string>();

  @Input()
  loading = false;

  @Output()
  signUp = new EventEmitter<GthSignUp>();

  @Output()
  login = new EventEmitter<void>();

  @Output()
  valueChange = new EventEmitter<void>();

  get appName() {
    return this.config.appName;
  }

  readonly privacyTabIndex = POLICY_TABS.privacyTabIndex;
  readonly termsConditionsTabIndex = POLICY_TABS.termsConditionsTabIndex;
  readonly notificationsTabIndex = POLICY_TABS.notificationsTabIndex;

  hidePassword = true;
  hideConfirmPassword = true;
  attempted = false;
  previousAttempt?: SignUpAttempt;
  profileImgError = false;

  get passwordMismatch$() {
    return this.passwordMismatchSubject.asObservable();
  }

  private passwordMismatchSubject = new BehaviorSubject(false);

  get passwordStrength() {
    const ctrl = this.formGroup.get('passwordStrength');
    return ctrl ? ctrl.value : 0;
  }

  passwordMatchValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  };

  formGroup = new UntypedFormGroup({
    fullName: new UntypedFormControl(''),
    email: new UntypedFormControl('', [
      Validators.email,
    ]),
    password: new UntypedFormControl(''),
    confirmPassword: new UntypedFormControl(''),
    genders: new UntypedFormControl([]),
    sports: new UntypedFormControl([]),
    photoUrl: new UntypedFormControl(''),
    pronouns: new UntypedFormControl(''),

    passwordStrength: new UntypedFormControl(''),
    termsAndConditions: new UntypedFormControl(false, Validators.requiredTrue),
  }, { validators: this.passwordMatchValidator });

  constructor(
    @Inject(GTH_ENVIRONMENT)
    private config: GthEnvironment,
    private safeStorage: SrvSafeStorageService,
    private policyDialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
  ) {
    /** Listen for Form Changes */
    this.formGroup.valueChanges.forEach((form) => {
      if (form.password.length > 0 && form.confirmPassword.length > 0) {
        /** Check for password mismatch */
        const misMatched = form.password !== form.confirmPassword;
        this.passwordMismatchSubject.next(misMatched);
        this.formGroup.get('confirmPassword')
          .setErrors(misMatched ? { notMatched: true } : null);
      }
      const contract = this.formGroup.getRawValue();
      const { email } = contract;
      this.valueChange.emit();
      if (this.previousAttempt && email !== this.previousAttempt.email) {
        this.reset();
      }
    });

    effect(() => {
      this.formGroup.get('email').setValue(this.email());
      this.formGroup.get('fullName').setValue(this.name());
    });
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  /**
   * Reset method typically called after auth fails
   */
  reset() {
    this.attempted = false;
  }

  /**
   * Event Handler for Sign Up Button
   * @return {void}
   */
  onSignUpButtonClick() {
    if (!this.formGroup.valid) {
      return;
    }
    const contract = this.formGroup.getRawValue();
    delete contract.passwordStrength;
    delete contract.termsAndConditions;

    this.setPreviousAttempt({
      email: contract.email,
      lastAttempt: new Date(),
    });

    this.attempted = true;
    this.loading = true;
    this.signUp.emit({
      contract,
      signInType: LoginType.Email,
    });
  }

  onLoginButtonClick() {
    this.login.emit();
  }

  /**
   * Event Handler for Google Social Button
   * @return {void}
   */
  onGoogleSignUpButtonClick() {
    this.signUp.emit({
      signInType: LoginType.Google,
    });
  }

  /**
   * Event Handler for Facebook Social Button
   * @return {void}
   */
  onFacebookSignUpButtonClick() {
    this.signUp.emit({
      signInType: LoginType.Facebook,
    });
  }

  onPasswordChange() {
    const passwordCtrl = this.formGroup.get('password');
    const passwordStrengthCtrl = this.formGroup.get('passwordStrength');
    if (!passwordCtrl || !passwordStrengthCtrl) {
      return;
    }
    const password = passwordCtrl.value;
    passwordStrengthCtrl.setValue(GthPasswordUtil.getPasswordStrength(password));
  }

  openPoliciesDialog(tabIndex: any) {
    this.policyDialog.open(GthPolicyDialogComponent, {
      data: tabIndex,
      backdropClass: 'gth-overlay-backdrop',
    });
  }

  private setPreviousAttempt(attempt: SignUpAttempt) {
    this.previousAttempt = attempt;
    if (!this.safeStorage) {
      return undefined;
    }
    this.safeStorage.setItem(LOGIN_STORE, JSON.stringify(attempt));
  }
}
