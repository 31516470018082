<!-- <app-first-visit-stepper [class.inDialog]="inDialog()" (completed)="onCompleteStepper()" /> -->

  <div class="sign-up-form" [class.inDialog]="inDialog()">
    <a class="logo" [routerLink]="routes.Root">
      <img src="assets/logo-g.webp" class="logo"  alt="app logo"/>
    </a>
    <gth-sign-up-form
      [signInRoute]="loginRoute()"
      [loading]="loading()"
      [email]="userEmail()"
      [name]="userInfo()?.fullName"
      (signUp)="onSignUp($event)"
    ></gth-sign-up-form>
  </div>

  <input class="cpy-input" #urlInput [value]="currentUrl" readonly>
