import { createReducer, on } from '@ngrx/store';

import * as JoinerActions from './../joiners/actions';
import * as EventActions from './actions';
import { EventState } from './state';

export const initialEventState: EventState = {
  events: {},
  joiners: {},
};

export const eventReducer = createReducer(
  initialEventState,
  on(EventActions.eventLoadOneSuccess, (state, { event }) => ({
    ...state,
    events: {
      ...state.events,
      ...(event ? { [event.id]: event } : {}),
    },
  })),
  on(EventActions.eventLoadListSuccess, (state, { events, joiners }) => {
    const updatedEvents = events.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }), {});
    return {
      ...state,
      events: {
        ...state.events,
        ...updatedEvents,
      },
      joiners: {
        ...state.joiners,
        ...joiners,
      },
    };
  }),
  on(JoinerActions.joinerLoadListSuccess, (state, successData ) => {
    const newState = {
      ...state,
      joiners: {
        ...state.joiners|| {},
        [successData.eventId]: successData.joiners,
      },
    };
    return newState;
  }),
);
