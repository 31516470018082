<h3>PRIVACY POLICY</h3>
<br />
<p>
  This Privacy Policy (this “<strong><i>Privacy Policy</i></strong
  >”) for Gametime Hero, Inc. (doing business as “Gametime Hero”) (the “<strong
    ><i>Company</i></strong
  >,” “<strong><i>we</i></strong
  >,” “<strong><i>us</i></strong
  >,” or “<strong><i>our</i></strong
  >”), describes how and why we might access, collect, store, use, and/or share your personal
  information when you use our services (the “<strong><i>Services</i></strong
  >”), visit our website at www.gametimehero.com (the “<strong><i>Website</i></strong
  >”), or download and/or use any application (each, an “<strong><i>Application</i></strong
  >”) of ours that links to this Privacy Policy. By using the Website, Services, or
  Applications, you agree to the collection and use of information in accordance with this
  Privacy Policy.
</p>

<p>
  This Privacy Policy does not apply to any of the personal information that our customers may
  collect and process using our services (“<strong><i>Customer Data</i></strong
  >”). Our customers’ respective privacy policies govern their collection and use of Customer
  Data. Our processing of Customer Data is governed by the contracts that we have in place with
  our customers. Any questions or requests relating to Customer Data should be directed to the
  applicable customer.
</p>

<h4>1. WHAT INFORMATION DO WE COLLECT?</h4>

<p>
  The categories of personal information we collect depend on how you interact with us, our
  Services, and the requirements of applicable law. We collect information that you provide to
  us, information that we obtain automatically when you use our Services, and information from
  other sources such as third-party services and organizations.
</p>

<h4>1.1 Personal Information You Provide to Us</h4>

<p>
  We collect personal information that you voluntarily provide to us, including when you
  register for our Services or Applications, express an interest in obtaining information about
  us or our products and Services, or participate in activities using our Applications or
  Website.
</p>

<p>
  The personal information that we collect depends on the context of your interactions with us,
  the choices you make, and the Services that you use. The personal information that we collect
  may include the following:
</p>

<ul class="flex flex-col gap-2">
  <li>Names</li>
  <li>Phone numbers</li>
  <li>Email addresses</li>
  <li>Mailing addresses</li>
  <li>Debt/credit card numbers</li>
  <li>Billing addresses</li>
  <li>Contact or authentication data</li>
  <li>Contact Preferences</li>
  <li>Passwords</li>
  <li>Usernames</li>
  <li>Job titles</li>
</ul>

<p>
  When necessary, with your consent or as otherwise permitted by applicable law, we process the
  following categories of sensitive information:
</p>

<ul class="flex flex-col gap-2">
  <li>Health data</li>
  <li>Financial data</li>
  <li>Genetic data</li>
  <li>Biometric data</li>
  <li>Data about a person’s sex life or sexual orientation</li>
  <li>Social security numbers or other government identifiers</li>
  <li>Student data</li>
  <li>Credit worthiness data</li>
  <li>Information revealing trade union membership</li>
  <li>Information revealing religious or philosophical beliefs</li>
  <li>Information revealing political opinions</li>
  <li>Information revealing race or ethnic origin</li>
</ul>

<p>
  We may collect data necessary to process your payment if you chose to make purchases, such as
  your payment instrument number, and the security code associated with your payment
  instrument.
</p>

<p>
  We may provide you with the option to register with us using your existing social media
  account details. If you choose to register in this way, we will collect certain profile
  information about you from the social media provider, as described further herein.
</p>

<p>
  If you use our Application(s), we may also collect the following information if you choose to
  provide us with access or permission:
</p>

<ul class="flex flex-col gap-4">
  <li>
    <i>Geolocation Information.</i> We may request access or permission to track location-based
    information from your mobile device, either continuously or while you are using our
    Application(s), to provide certain location-based services. If you wish to change our
    access or permissions, you may do so in your device’s settings.
  </li>
  <li>
    <i>Mobile Device Access.</i> We may request access or permission to certain features from
    your mobile device, including your mobile device’s Bluetooth, calendar, camera, contacts,
    storage, social media accounts, SMS messages, reminders, microphone, sensors, and other
    features. If you wish to change our access or permissions, you may do so in your device’s
    settings.
  </li>
  <li>
    <i>Mobile Device Data.</i> We automatically collect device information (such as your mobile
    device ID, model, and manufacturer), operating system, version information and system
    configuration information, device and application identification numbers, browser type and
    version, hardware model, internet service provider and/or mobile carrier, and internet
    protocol (IP) address (or proxy server). If you are using our Application(s), we may also
    collect information about the phone network associated with your mobile device, your mobile
    device’s unique device ID, and information about the features of our Application(s) you
    accessed.
  </li>
  <li>
    <i>Push Notifications.</i> We may request to send you push notifications regarding your
    account or certain features of the Application(s). If you wish to opt out from receiving
    these types of communications, you may turn them off in your device’s settings.
  </li>
</ul>

<p>
  This information is primarily needed to maintain the security and operation of our
  Application(s), for troubleshooting, and for our own internal analytics and reporting
  purposes.
</p>

<p>
  All personal information that you provide to us must be true, complete, and accurate, and you
  must notify us of any changes to such personal information.
</p>

<h4>1.2 Automatically Collected Information</h4>

<p>
  We automatically collect certain information when you interact with our Services, Website, or
  Applications. This information does not reveal your specific identity (like your name or
  contact information), but may include device and usage information, such as your IP address,
  browser and device characteristics, operating system, language preferences, referring URLs,
  device name, country, location, information about how and when you use our Services, Website,
  and/or Applications, and other technical information. This information is primarily needed to
  maintain the security and operation of our Services, Website, and Applications, and for our
  internal analytics and reporting purposes. Like many businesses, we also collect information
  through cookies and similar technologies. The information we collect includes:
</p>

<ul class="flex flex-col gap-4">
  <li>
    <i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage, and
    performance information our servers automatically collect when you access or use our
    Services, Website, or Applications, and which we record in log files. Depending on how you
    interact with us, this log data may include your IP address, device information, browser
    type, and settings and information about your activity (such as the date/time stamps
    associated with your usage, pages and files viewed, searches, and other actions you take,
    such as which features you choose to use), device event information (such as system
    activity, error reports, and hardware settings).
  </li>
  <li>
    <i>Device Data.</i> We collect device data such as information about your computer, phone,
    tablet, or other device you use to access the Services. Depending on the device used, this
    device data may include information such as your IP address (or proxy server), device and
    application identification numbers, location, browser type, hardware model, Internet
    service provider, and/or mobile carrier, operating system, and system configuration
    information.
  </li>
  <li>
    <i>Location Data.</i> We collect location data such as information about your device’s
    location, which can be either precise or imprecise. How much information we collect depends
    on the type and settings of the device you use to access the Services. For example, we may
    use GPS and other technologies to collect geolocation data that tells us your current
    location (based on your IP address). You can opt out of allowing us to collect this
    information either by refusing access to the information or by disabling your location
    setting on your device. However, if you choose to opt out, you may not be able to use
    certain aspects of the Services.
  </li>
</ul>

<h4>1.3 Information Collected from Other Sources</h4>

<p>
  In order to enhance our ability to provide relevant marketing, offers, and services to you
  and update our records, we may obtain information about you from other sources, such as
  public databases, joint marketing partners, affiliate programs, data providers, social media
  platforms, and from other third parties. This information includes mailing addresses, job
  titles, email addresses, phone numbers, intent data (or user behavior data), Internet
  Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for
  purposes of targeting advertising and event promotion.
</p>

<p>
  If you interact with us on a social media platform using your social media account, we
  receive personal information about you from such platforms, including your name, email
  address, and gender. You may have the right to withdraw your consent to processing your
  personal information. Any personal information that we collect from your social media account
  depends on your social media account’s privacy settings.
</p>

<h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>

<p>
  We process your personal information for a variety of reasons, depending on how you interact
  with our Services, including:
</p>

<ul class="flex flex-col gap-4">
  <li>
    <i
      >To facilitate account creation and authentication and otherwise manage user accounts.</i
    >
    We may process your information so you can create and log in to your account, as well as
    keep your account in working order.
  </li>
  <li>
    <i>To deliver and facilitate delivery of services to the user.</i> We may process your
    information to provide you with the requested service.
  </li>
  <li>
    <i>To respond to user inquiries/offer support to users.</i> We may process your information
    to respond to your inquiries and solve any potential issues you might have with the
    requested service.
  </li>
  <li>
    <i>To send administrative information to you.</i> We may process your information to send
    you details about our products and services, changes to our terms and policies, and other
    similar information.
  </li>
  <li>
    <i>To fulfill and manage your orders.</i> We may process your information to fulfill and
    manage your orders, payments, returns, and exchanges made through the Services.
  </li>
  <li>
    <i>To enable user-to-user communications.</i> We may process your information if you choose
    to use any of our offerings that allow for communication with another user.
  </li>
  <li>
    <i>To request feedback.</i> We may process your information when necessary to request
    feedback and to contact you about your use of our Services.
  </li>
  <li>
    <i>To send you marketing and promotional communications.</i> We may process the personal
    information you send to us for our marketing purposes if this is in accordance with your
    marketing preferences. You can opt out of our marketing emails at any time.
  </li>
  <li>
    <i>To deliver targeted advertising to you.</i> We may process your information to develop
    and display personalized content and advertising tailored to your interests, location, and
    more.
  </li>
  <li>
    <i>To protect our Services.</i> We may process your information as part of our efforts to
    keep our Services safe and secure, including fraud monitoring and prevention.
  </li>
  <li>
    <i>To identify usage trends.</i> We may process information about how you use our Services
    to better understand how they are being used so that we can improve them.
  </li>
  <li>
    <i>To determine the effectiveness of our marketing and promotional campaigns.</i> We may
    process your information to better understand how to provide marketing and promotional
    campaigns that are most relevant to you.
  </li>
  <li>
    <i>To save or protect an individual’s vital interest.</i> We may process your information
    when necessary to save or protect an individual’s vital interest, such as to prevent harm.
  </li>
</ul>

<h4>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>

<h4>3.1 Vendors, Consultants, and Other Third-Party Service Providers</h4>

<p>
  We may share your data with third-party vendors, service providers, contractors, or their
  agents (“<strong><i>third parties</i></strong
  >”) who perform services for us or on our behalf and require access to such information to do
  that work. We have contracts in place with our third parties that are designed to help
  safeguard your personal information. This means that they cannot do anything with your
  personal information unless we have instructed them to do it. They also commit to protect the
  data they hold on our behalf and to retain it for the period we instruct.
</p>

<p>The categories of third parties we may share personal information with are as follows:</p>

<ul class="flex flex-col gap-2">
  <li>Ad Networks</li>
  <li>Affiliate Marketing Programs</li>
  <li>Website Hosting Service Providers</li>
  <li>User Account Registration & Authentication Services</li>
  <li>Testing Tools</li>
  <li>Social Networks</li>
  <li>Sales & Marketing Tools</li>
  <li>Retargeting Platforms</li>
  <li>Product Engineering & Design Tools</li>
  <li>Performance Monitoring Tools</li>
  <li>AI Platforms</li>
  <li>Cloud Computing Services</li>
  <li>Communication & Collaboration Tools</li>
  <li>Data Analytics Services</li>
  <li>Data Storage Service Providers</li>
  <li>Finance & Accounting Tools</li>
  <li>Government Entities</li>
  <li>Order Fulfillment Service Providers</li>
  <li>Payment Processors</li>
</ul>

<p>We also may need to share your personal information in the following situations:</p>

<ul class="flex flex-col gap-4">
  <li>
    <i>Business Transfers.</i> We may share or transfer your information in connection with, or
    during negotiations of, any merger, sale of company assets, financing, or acquisition of
    all or a portion of our business to another company.
  </li>
  <li>
    <i>Affiliates.</i> We may share your information with our affiliates, in which case we will
    require those affiliates to honor this Privacy Policy.
  </li>
  <li>
    <i>Business Partners.</i> We may share your information with our business partners to offer
    you certain products, services, or promotions.
  </li>
  <li>
    <i>Other Users.</i> When you share personal information (for example, by posting comments,
    contributions, or other content) or otherwise interact with the public aspects of the
    Services, Website, or Application(s), such personal information may be viewed by all users
    and may be publicly made available outside the Website, Services, and Application(s) in
    perpetuity. If you interact with other users of our Services and register for our Services
    through a social network, your contacts on the social network will see your name, profile
    photo, and descriptions of your activity. Similarly, other users will be able to view
    descriptions of your activity, communicate with you within our Services, and view your
    profile.
  </li>
  <li>
    <i>Offer Wall.</i> Our Application(s) may display a third-party hosted “offer wall.” Such
    an offer wall allows third-party advertisers to send virtual currency, gifts, or other
    items to users in return for the acceptance and completion of an advertisement offer. Such
    an offer wall may appear in our Application(s) and be displayed to you based on certain
    data, such as your geographic area or demographic information. When you click on an offer
    wall, you will be brought to an external website belonging to other persons and will leave
    our Application(s). A unique identifier, such as your user ID, will be shared with the
    offer wall provider in order to prevent fraud and properly credit your account with the
    relevant reward.
  </li>
</ul>

<h4>3.2 Third-Party Websites</h4>

<p>
  The Services, including our offer wall, may link to third-party websites, online services, or
  mobile applications and/or contain advertisements from third parties that are not affiliated
  with us and which may link to other websites, services, or applications. Accordingly, we do
  not make any guarantee regarding any such third parties, and we will not be liable for any
  loss or damage caused by the use of such third-party websites, services, or applications. The
  inclusion of a link towards a third-party website, service, or application does not imply an
  endorsement by us. We cannot guarantee the safety and privacy of data you provide to any
  third-party websites. Any data collected by third parties is not covered by this Privacy
  Policy. We are not responsible for the content or privacy and security practices and policies
  of any third parties, including other websites, services, or applications that may be linked
  to or from the Services. You should review the policies of such third parties and contact
  them directly to respond to your questions.
</p>

<h4>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>

<p>
  We may use cookies and similar tracking technologies (like web beacons and pixels) to gather
  information when you interact with our Services. Some online tracking technologies help us
  maintain the security of our Services and your account, prevent crashes, fix bugs, save your
  preferences, and assist with basic site functions.
</p>

<p>
  We also permit third parties and service providers to use online tracking technologies on our
  Services for analytics and advertising, including to help manage and display advertisements,
  to tailor advertisements to your interests, or to send abandoned shopping cart reminders
  (depending on your communication preferences). The third parties and service providers use
  their technology to provide advertising about products and services tailored to your
  interests, which may appear either on our Services or on other websites.
</p>

<p>
  To the extent these online tracking technologies are deemed to be a “sale” or “sharing”
  (which includes targeting advertising, as defined by applicable laws) under applicable United
  States state laws, you can opt out of these online tracking technologies by submitting a
  request as described herein.
</p>

<h4>5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS OR SERVICES?</h4>

<p>
  As part of our Services, we offer products, features, or tools powered by artificial
  intelligence, machine learning, or similar technologies (collectively, “<strong
    ><i>AI Products</i></strong
  >”). These tools are designed to enhance your experience and provide you with innovative
  solutions. The terms in this Privacy Policy govern your use of the AI Products within our
  Services.
</p>

<p>
  All personal information processed using our AI Products is handled in line with this Privacy
  Policy and applicable agreements that we may have with third parties in order to ensure
  security and safeguard your personal information.
</p>

<h4>6. HOW DO WE HANDLE YOUR SOCIAL LOG-INS?</h4>

<p>
  Our Services offer you the ability to register and log in using your third-party social media
  account details. Where you choose to use this function, we will receive certain profile
  information about you from your social media provider. The profile information we receive may
  vary depending on the social media provider concerned, but will often include your name,
  email address, friends lit, and profile picture, as well as other information you choose to
  make public on such social media platform. If you log in using a social media platform, we
  may also request access to other permissions related to your account, and you may choose to
  grant or deny us access to each individual permission.
</p>

<p>
  We will use the information we receive only for the purposes that are described in this
  Privacy Policy or that are otherwise made clear to you on the relevant Services. Please note
  that we do not control, and are not responsible for, other uses of your personal information
  by your third-party social media provider. We recommend that you review their privacy notice
  to understand how they collect, use, and share your personal information, and how you can set
  your privacy preferences on their sites and apps.
</p>

<h4>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>

<p>
  We will only keep your personal information for as long as it is necessary for the purposes
  set out in this Privacy Policy, unless a longer retention period is required or permitted by
  law (such as tax, accounting, or other legal requirements). No purpose in this notice will
  require us to keep your personal information for longer than the period of time in which you
  have an account with us.
</p>

<p>
  When we have no ongoing need to process your personal information, we will either delete or
  anonymize such information, or, if this is not possible (for example, because your personal
  information has been stored in backup archives), then we will securely store your personal
  information and isolate it from any further processing until deletion is possible.
</p>

<h4>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>

<p>
  We have implemented appropriate and reasonable technical and organizational security measures
  designed to protect the security of any personal information we process. However, despite our
  safeguards and efforts to secure your information, no electronic transmission over the
  internet or information storage technology can be guaranteed to be 100% secure, so we cannot
  promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will
  not be able to defeat our security and improperly collect, access, steal, or modify your
  information. Although we will do our best to protect your personal information, transmission
  of personal information to and from our Services is at your own risk. You should only access
  the Services within a secure environment.
</p>

<h4>9. WHAT ARE YOUR PRIVACY RIGHTS?</h4>

<p>
  In some regions (including the EEA, UK, Switzerland, and Canada), you have certain rights
  under applicable data protection laws. These may include the right (i) to request access and
  obtain a copy of your personal information; (ii) to request rectification or erasure; (iii)
  to restrict the processing of your personal information; (iv) if applicable, to data
  portability; and (v) not to be subject to automated decisionmaking. In certain circumstances,
  you may also have the right to object to the processing of your personal information. We will
  consider and act upon any request in accordance with applicable data protection laws.
</p>

<h4>10. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h4>

<h4>10.1 Europe and the United Kingdom: General Data Protection Regulation (“GDPR”)</h4>

<p>We may process personal data under the following conditions:</p>

<ul class="flex flex-col gap-4">
  <li>
    <i>Consent.</i> We may process your information if you have given us permission (i.e.,
    consent) to use your personal information for a specific purpose. You can withdraw your
    consent at any time.
  </li>
  <li>
    <i>Performance of a Contract.</i> We may process your personal information when we believe
    it is necessary to fulfill our contractual obligations to you, including providing our
    Services or entering into a contract with you at your request.
  </li>
  <li>
    <i>Legitimate Interests.</i> We may process your information when we believe it is
    reasonably necessary to achieve our legitimate business interests and those interests do
    not outweigh your interests and fundamental rights and freedoms.
  </li>
  <li>
    <i>Legal Obligations.</i> We may process your information where we believe it is necessary
    for compliance with our legal obligations, such as to cooperate with a law enforcement body
    or regulatory agency, exercise or defend our legal rights, or disclose your information as
    evidence in litigation in which we are involved.
  </li>
  <li>
    <i>Vital Interests.</i> We may process your information where we believe it is necessary to
    protect your vital interests or the vital interests of a third party, such as situations
    involving potential threats to the safety of any person.
  </li>
</ul>

<p>
  You have the right under these Terms, and by law if you are within the European Union or the
  United Kingdom, to: (i) request access to your personal data; (ii) request correction of the
  personal data that we hold about you; (iii) object to the processing of your personal data if
  your particular situation gives you grounds to do so; (iv) request erasure of your personal
  data held by us when there is no good reason for us to continue processing it; (v) request
  the transfer of your personal data; or (vi) withdraw your consent on using your personal
  data. You may exercise these rights by contacting us using the contact information listed in
  Section XI.H below. Please note that we may ask you to verify your identity before responding
  to such request. You also have the right to complain to a data protection authority about our
  collection and use of your personal data by contacting your local data protection authority.
</p>

<h4>10.2 Canada</h4>

<p>
  If you reside in Canada, this section applies to you. We may process your information if you
  have given us specific permission (i.e., express consent) to use your personal information
  for a specific purpose, or in situations where your permission can be inferred (i.e., implied
  consent). You can withdraw your consent at any time.
</p>

<p>
  In some exceptional cases, we may be legally permitted under applicable law to process your
  information without your consent, including, for example:
</p>

<ul class="flex flex-col gap-2">
  <li>
    If collection is clearly in the interest of an individual and consent cannot be obtained in
    a timely way
  </li>
  <li>For investigations and fraud detection and prevention</li>
  <li>For business transactions provided certain conditions are met</li>
  <li>
    If it is contained in a witness statement and the collection is necessary to assess,
    process, or settle an insurance claim
  </li>
  <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
  <li>
    If we have reasonable grounds to believe an individual has been, is, or may be victim of
    financial abuse
  </li>
  <li>
    If it is reasonable to expect collection and use with consent would compromise the
    availability or the accuracy of the information and the collection is reasonable for
    purposes related to investigating a breach of an agreement or a contravention of the laws
    of Canada or a province
  </li>
  <li>
    If disclosure is required to comply with a subpoena, warrant, court order, or rules of the
    court relating to the production of records
  </li>
  <li>
    If it was produced by an individual in the course of their employment, business, or
    profession and the collection is consistent with the purposes for which the information was
    produced
  </li>
  <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
  <li>If the information is publicly available and is specified by the regulations</li>
</ul>

<h4>10.3 California: California Consumer Privacy Act Of 2018 (the “CCPA”)</h4>

<p>
  This section of the Privacy Policy applies solely to residents of the State of California who
  interact with our Website, Services, or Application(s).
</p>

<h4>(a) Categories of Personal Information Collected</h4>

<p>
  We collect information that identifies, relates to, describes, references, is capable of
  being associated with, or could reasonably be linked, directly or indirectly, with a
  particular consumer or device. The following is a list of categories of personal information
  that we may collect or may have collected from California residents within the last twelve
  months. Please note that the categories and examples provided in the lit below are defined in
  the CCPA.
</p>

<ul class="flex flex-col gap-4">
  <li>
    <i>Identifiers.</i> A real name, alias, postal address, unique personal identifier, online
    identifier, Internet Protocol address, email address, account name, social security number,
    or other similar identifiers.
  </li>
  <li>
    <i
      >Personal Information Categories Listed in the California Customer Records Statute (Cal.
      Civ. Code Section 1798.80(e)).</i
    >
    A name, signature, social security number, physical characteristics or description,
    address, telephone number, passport number, driver’s license or state identification card
    number, insurance policy number, education, employment, employment history, bank account
    number, credit card number, debit card number, or any other financial information, medical
    information, or health insurance information. Personal information does not include
    publicly available information that is lawfully made available to the general public from
    federal, state, or local government records. Please note that some personal information
    categories included in this list may overlap with other categories.
  </li>
  <li>
    <i>Protected Classification Characteristics Under California or Federal Law.</i> Age (40
    years or older), race, color, ancestry, national origin, citizenship, religion or creed,
    marital status, medical condition, physical or mental disability, sex (including gender,
    gender identity, gender expression, pregnancy or childbirth and related medical
    conditions), sexual orientation, veteran or military status, genetic information (including
    familial genetic information).
  </li>
  <li>
    <i>Commercial Information.</i> Records and history of products or services purchased or
    considered.
  </li>
  <li>
    <i>Biometric Information.</i> Genetic, physiological, behavioral, and biological
    characteristics, or activity patterns used to extract a template or other identifier or
    identifying information, such as fingerprints, faceprints, and voiceprints, iris or retina
    scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.
  </li>
  <li>
    <i>Internet or Other Similar Network Activity.</i> Interaction with our Website, Services,
    or advertisements.
  </li>
  <li><i>Geolocation Data.</i> Approximate physical location.</li>
  <li>
    <i>Sensory Data.</i> Audio, electronic, visual, thermal, olfactory, or similar information.
  </li>
  <li>
    <i>Professional or Employment-related Information.</i> Current or past job history or
    performance evaluations.
  </li>
  <li>
    <i
      >Non-public Education Information (per the Family Educational Rights and Privacy Act, 20
      U.S.C. § 1232(g), 34 C.F.R. Part 99)).</i
    >
    Education records directly related to a student maintained by an educational institution or
    party acting on its behalf, such as grades, transcripts, class lists, student schedules,
    student identification codes, student financial information, or student disciplinary
    records.
  </li>
  <li>
    <i>Inferences Drawn from Other Personal Information.</i> Profile reflecting a person’s
    preferences, characteristics, psychological trends, predispositions, behavior, attitudes,
    intelligence, abilities, and aptitudes.
  </li>
</ul>

<h4>(b) “Sales” of Personal Information Under the CCPA</h4>

<p>
  For purposes of the CCPA, we do not “sell” personal information, nor do we have actual
  knowledge of any “sale” of personal information, nor do we have actual knowledge of any
  “sale” of personal information of minors under 16 years of age.
</p>

<h4>(c) Additional Privacy Rights for California Residents</h4>

<p>
  The CCPA provides California residents with specific rights regarding their personal
  information. If you are a resident of California, you have the following rights: (i) the
  right to be notified as to which categories of personal data are being collected and the
  purposes for which the personal data is used; (ii) the right to request that we disclose
  information to you about our collection, use, sale, disclosure for business purposes and
  sharing of personal information; (iii) the right to direct us not to sell your personal
  information by opting out of such transactions; (iv) the right to request the deletion of
  your personal data (subject to certain exceptions); and (v) the right not to be discriminated
  against for exercising your consumer rights. In order to contact us to exercise any of these
  rights, please reference the contact information set forth in Section XI.H below, and include
  in such request sufficient information to allow us to reasonably verify that you are the
  person about whom we collected personal information or an authorized representative and a
  description of your request with sufficient detail to allow us to properly understand,
  evaluate, and respond to it. We cannot respond to your request or provide you with the
  required information if we cannot verify your identity or authority to make the request and
  confirm that the personal information relates to you. We will disclose and deliver the
  required information free of charge within 45 days of receiving your verifiable request. The
  time period to provide the required information may be extended once by an additional 45 days
  when reasonably necessary and with prior notice. Any disclosures we provide will cover only
  the 12-month period preceding the verifiable request’s receipt. For data portability
  requests, we will select a format to provide your personal information that is readily usable
  and should allow you to transmit the information from one entity to another entity without
  hindrance.
</p>

<h4>(d) California’s Shine the Light Law</h4>

<p>
  Under Cal. Civ. Code §1798 (the “<strong><i>California Shine the Light Law</i></strong
  >”), California residents with an established business relationship with us can request
  information once a year about sharing their personal data with third parties for such third
  parties’ direct marketing purposes. If you’d like to request more information under the
  California Shine the Light Law, and if you are a California resident you can contact us using
  the contact information provided in Section XI.H below.
</p>

<h4>(e) California’s Privacy Rights for Minors</h4>

<p>
  Cal. Bus. & Prof. Code § 22581 allows California residents under the age of 18 who are
  registered users of online sites, services, or applications to request and obtain removal of
  content or information they have publicly posted. To request removal of such data, and if you
  are a California resident, you can contact us using the contact information provided in
  Section XI.H below. Please include the email address associated with your account in such
  communication. Please also be aware that your request does not guarantee complete or
  comprehensive removal of content or information posted online and that the law may not permit
  or require removal in certain circumstances.
</p>

<h4>10.4 Nevada: Supplemental Notice for Nevada Residents</h4>

<p>
  If you are a resident of Nevada, you have the right to opt out of the sale of certain
  personal information to third parties who intend to license or sell that personal
  information. You can exercise this right by contacting us at
  <a target="_blank" href="mailto:info@gametimehero.com">info&#64;gametimehero.com</a> with the
  subject line “Nevada Do Not Sell Request” and providing us with your name and the email
  address associated with your account. Please note that we do not currently sell your personal
  information as sales are defined in the Nevada Revised Statutes Chapter 603A.
</p>

<h4>10.5 Other United States Jurisdictions</h4>

<p>
  If you are a resident of Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky,
  Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have
  the right to request access to and receive details about the personal information we maintain
  about you and how we have processed it, correct inaccuracies, get a copy of, or delete your
  personal information. You may also have the right to withdraw your consent to our processing
  of your personal information. These rights may be limited in some circumstances by applicable
  law.
</p>
