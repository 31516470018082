import { inject, Injectable, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ANALYTICS_CONSTS, APP_STATE, eventReducer, GthAnalyticsService, GthAuthService, GthCloudFunctionService, GthSignUp, GthSignUpContract, GthSignUpFormComponent } from '@gth-legacy';
import { LoginType } from '@index/enums';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';
import { AutoLoadService } from '@sentinels/services/core/auto-load.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { unregisteredUserLoadOneByEmail, unregisteredUserSignUp } from '@sentinels/state/features/unregistered-user/actions';
import { unregisteredUserReducer } from '@sentinels/state/features/unregistered-user/reducers';
import { selectUnregisteredUserByEmail } from '@sentinels/state/features/unregistered-user/selectors';
import { filter, finalize, first, switchMap, tap } from 'rxjs/operators';

import { SharedAuthService } from '../../../services/shared-auth.service';
import { LoginService } from '../../login/services/login.service';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  private analytics = inject(GthAnalyticsService);
  private safeStorage = inject(SrvSafeStorageService);
  private snackBar = inject(MatSnackBar);
  private auth = inject(GthAuthService);
  /** fixme: removing old cloud function service below breaks app from loading */
  private cloud = inject(GthCloudFunctionService);
  private sharedAuthService = inject(SharedAuthService);
  private loginService = inject(LoginService);
  private store = inject(Store<APP_STATE>);
  private autoload = inject(AutoLoadService);
  private usersService = inject(UserService);

  public loading = signal(false);
  public signedUp = signal(false);

  constructor() {
    this.store.addReducer('unregisteredUser', unregisteredUserReducer);
    this.store.addReducer('event', eventReducer);
  }

  get prevSavedRoute() {
    return this.safeStorage.getItem('routeAfterLogin');
  }

  public async onSignUp(contract: GthSignUp, form?: GthSignUpFormComponent) {
    const validBrowser = this.sharedAuthService.isBrowserValid(contract.signInType);
    if (!validBrowser) {
      return;
    }
    this.analytics.logEvent(ANALYTICS_CONSTS.sign_up);

    switch (contract.signInType) {
      case LoginType.Google:
      await this.signUpWithGoogle();
        break;
      case LoginType.Email:
        await this.signUpWithEmail(contract.contract, form);
        break;
      default: break;
    }
  }

  private handleUnsuccessfulSignUp(
    user: GthUserModel,
    contract: GthSignUpContract,
    form?: GthSignUpFormComponent,
  ) {
    if (user && user.emailVerified) {
      // User already exists
      // Someone's already using that email.
      this.snackBar.open('Someone\'s already using that email.', 'OK');
      form?.reset();
    } else if (user && !user.emailVerified) {
      // User exists, but wasn't verified
      this.auth.resendVerificationMail$(contract.email, contract.password).pipe(
        first(),
      )
      .toPromise();
      this.snackBar.open('Check your email for validation', 'OK');
    } else {
      // No user, something else is funky
      this.snackBar.open('Error signing up, please try again.', 'OK');
      form?.reset();
    }
  }

  private async signUpWithEmail(
    contract?: GthSignUpContract,
    form?: GthSignUpFormComponent,
  ) {
    if (!contract) {
      return;
    }
    this.loading.set(true);
    return this.auth.signUpWithEmail$(contract.email, contract.password).pipe(
      first(),
      finalize(() => {
        this.loading.set(false);
        this.signedUp.set(true);
      }),
      switchMap((success) => {
        if (success) {
          this.loginService.onLogin({
            ...contract,
            rememberMe: false,
            loginType: LoginType.Email,
          });
          return this.checkForUnregisteredUser(contract.email);
        }

        return this.usersService.getUserByEmail$(contract.email).pipe(
          first(),
          tap((user) => {
            this.handleUnsuccessfulSignUp(user, contract, form);
          }),
        );
      }),
    ).subscribe();
  }

  private checkForUnregisteredUser(email: string) {
    const unregisteredAccount$ = this.autoload.autoLoadIfNotFound(
      selectUnregisteredUserByEmail(email),
      unregisteredUserLoadOneByEmail({ email: email }),
    );
    return unregisteredAccount$.pipe(
      filter((user) => !!user),
      first(),
      switchMap((user) => {
        return this.store.select(selectUser).pipe(
          first(),
          tap((currentUser) => {
            if (currentUser) {
              this.removeUnregisteredUser(user, currentUser.uid);
            }
          }),
        );
      }),
    );
  }

  private removeUnregisteredUser(user: GthUnregisteredUserModel, newUserId: string) {
    this.store.dispatch(unregisteredUserSignUp({ uid: user.uid, newUid: newUserId }));

    this.safeStorage.removeItem('UNREGISTERED_RSVPS');
    this.safeStorage.removeItem('UNREGISTERED_CONTACT_INFO');
  }

  private async signUpWithGoogle() {
    this.auth.storeGoogleLogin();
  }
}
