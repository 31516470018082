import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  Optional,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { GthSignUpFormComponent, GthSignUpFormModule } from '@gth-legacy';
import { LoginType } from '@index/enums';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { APP_ROUTES } from '@shared/helpers';

import { AuthLayoutComponent } from '../../layout/auth-layout.component';
import { SignUpService } from './services/sign-up.service';

const leftImage = {
  url: 'assets/biking2.webp',
  alt: 'Two people biking together up a hill',
};

export interface SignUpContract {
  fullName: string;
  email: string;
  password: string;
  genders: string[];
  sports: string[];
  photoUrl: string;
  pronouns: string;
}

export interface SignUp {
  contract?: SignUpContract;
  signInType: LoginType;
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss',
  imports: [RouterModule, GthSignUpFormModule, MatSnackBarModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent {
  public userInfo = input<GthUnregisteredUserModel>();
  public signUp = output<void>();

  private safeWindow = inject(SrvSafeWindowService);
  private safeStorage = inject(SrvSafeStorageService);
  private router = inject(Router);
  private activeRoute = inject(ActivatedRoute);
  private signUpService = inject(SignUpService);
  private snackbar = inject(MatSnackBar);
  destroyRef = inject(DestroyRef);

  public routes = APP_ROUTES;
  public inDialog = computed<boolean>(() =>
    this.router.url.includes('auth') || this.userInfo() ? true : false,
  );
  public loginRoute = computed(() =>
    this.inDialog() ? `${this.routes.Auth}/login` : this.routes.Login,
  );
  public loading = this.signUpService.loading;
  public isFirstVisit = signal(
    this.safeStorage.getItem('visitCount')
      ? parseInt(this.safeStorage.getItem('visitCount')) === 1
      : false,
  );
  public userEmail = signal(this.userInfo()?.email);

  private signUpCtrl = viewChild(GthSignUpFormComponent);

  constructor(@Optional() layout: AuthLayoutComponent) {
    if (layout) {
      layout.image.set(leftImage);
    }

    effect(() => {
      if (this.signUpService.signedUp()) {
        this.signUp.emit();
      }
    });

    // Pre-fill signup form with invitee email, if user is invited user
    this.activeRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        const encodedEmail = params['invitedEmail'];
        if (encodedEmail) {
          this.userEmail.set(encodedEmail);
        }
      });
  }

  get currentUrl() {
    return this.safeWindow.location?.href;
  }

  public async onSignUp(contract: SignUp) {
    try {
      this.loading.set(true);
      await this.signUpService.onSignUp(contract, this.signUpCtrl());
    } catch (e) {
      this.snackbar.open((e as Error).message || 'Failed to signup');
    } finally {
      this.loading.set(false);
    }
  }

  onCompleteStepper() {
    this.isFirstVisit.set(false);
  }
}
